.page {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  padding: 10px;
  row-gap: 10px;

  :global {
    .Table {
      table {
        table-layout: fixed;
      }
    }
  }

  .filters {
    display: none;
  }
}

.filters_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 12px;
}

.filters_status {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.table {
  grid-column: 2 / 2;

  table {
    table-layout: fixed;
  }
}

.paymentModifiedAtCol {
  cursor: help;
  font-size: 14px;
  line-height: 21px;

  p:nth-child(2) {
    color: #7c828d;
  }
}

.showMore {
  composes: btn-icon from "/src/index.scss";
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 8px;
  text-align: center;
  text-decoration-line: underline;
}
.openFiltersBtn {
  margin-right: auto;
}

.FiltersModal {
  width: 300px;
  min-width: 300px;
}

@media (min-width: 768px) {
  .page {
    :global {
      .Table {
        table {
          table-layout: auto;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .page {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: 20px auto;

    .filters {
      display: block;
      grid-column: 1 / 2;
      margin-right: 12px;
      padding: 0 16px;
      width: 240px;
    }

    .openFiltersBtn {
      display: none;
    }
  }
}

.page {
  @media (max-width: 1600px) {
    :global {
      .Table {
        overflow-x: auto;

        table {
          table-layout: auto;
        }
      }
    }
  }
}
