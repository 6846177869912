.root {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  gap: 10px;
  grid-column: 1;
  height: 64px;
  justify-content: space-between;
  padding: 10px 28px 10px 14px;
  position: relative;
}

.pageName {
  align-items: center;
  background: #ffffff;
  border: 1px solid #dcdfe4;
  border-radius: 5px;
  color: #7c828d;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  justify-content: center;
  line-height: 1;
  min-width: 120px;
  padding: 0 24px;
  text-transform: uppercase;
}

.actions {
  align-items: center;
  display: flex;
  gap: 20px;
}

@media (min-width: 1200px) {
  .root {
    grid-column: 2;
    padding-right: 45px;
  }
}
