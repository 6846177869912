.page {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  padding: 10px;

  :global {
    .Table {
      table {
        table-layout: fixed;
      }
    }
  }
}

.header {
  height: 49px;
}

@media (min-width: 768px) {
  .page {
    :global {
      .Table {
        table {
          table-layout: auto;
        }
      }
    }
  }
}
