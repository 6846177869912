.LessonDetails {
  max-width: 400px;
}

.header {
  border-bottom: 1px solid #e3e3e3;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}

.info {
  display: grid;
  font-size: 14px;
  grid-template-columns: 100px 1fr;
  line-height: 17px;
  padding-bottom: 10px;
  padding-top: 10px;
  row-gap: 10px;
}

.label {
  color: rgba(41, 45, 52, 0.9);
}

.materials {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 0;
  row-gap: 2px;
}

.footer {
  align-items: center;
  border-top: 1px solid #e3e3e3;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 12px;
  justify-content: flex-end;
  padding: 8px 0;
}

.LessonPaymentStatus {
  align-items: center;
  background-color: #91b5ac;
  border-radius: 3px;
  cursor: help;
  display: inline-flex;
  height: 15.5px;
  justify-content: center;
  width: 16px;

  svg {
    color: #ffffff;
    height: 11px;
  }
}

.closed {
  background-color: #91b5ac;
}

.paid {
  background-color: #ffffff;
  border: 1px solid #91b5ac;

  svg {
    color: #91b5ac;
  }
}

.awaits {
  background-color: #909498;
}

.afterDeadline {
  background-color: #b24b43;
}

.downloadLink {
  color: #197bbd;
  composes: btn-icon from "/src/index.scss";
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
}

.uploadButton {
  border: 1px dashed #909498;
  border-radius: 10px;
  color: #909498;
  composes: btn-icon from "/src/index.scss";
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  gap: 0;
  height: auto;
  justify-content: center;
  margin-top: 8px;
  padding: 4px 10px;
  width: 100%;
}

.fileInput {
  opacity: 0;
  visibility: hidden;
  width: 0;
}

.homeworksHeader {
  font-size: 14px;
  margin-bottom: 6px;
}

.homework {
  align-items: center;
  display: flex;
  gap: 6px;
  max-width: 100%;

  :global {
    .CompletedHomeworkPill {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
