.calendar {
  * {
    color: #292d34;
    font-family: "Poppins", sans-serif;
  }

  :global {
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__year-text--in-range {
      background-color: #197bbd;
      color: #ffffff;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--disabled {
      color: #ccc;
      display: none;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__input-time-container {
      border-top: 1px solid #aeaeae;
      margin: 0;
      padding: 8px;
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      cursor: default;
      color: #ccc;
    }
  }
}

.InputWrapper {
  color: #d6d6d6;
  position: relative;

  .input {
    caret-color: transparent;
    padding-left: 35px;
  }

  .img {
    bottom: 9px;
    left: 10px;
    position: absolute;
  }
}
