.modal {
  min-width: 300px;
  width: 300px;

  :global {
    .ModalContent {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
