.pageTitle {
  font-size: 30px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 4px;
  text-align: center;
}

.pageTitle_when {
  font-size: 26px;
  margin-top: 6px;
}

.submitBtn {
  justify-content: center;
  width: 110px;
}

.noAccountText {
  align-items: center;
  display: flex;
  font-size: 13px;
  gap: 8px;
  justify-content: center;
  margin-top: 12px;

  :global {
    .link {
      text-decoration: underline;
    }
  }
}

.materials {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 0;
  row-gap: 2px;
}

.downloadLink {
  color: #197bbd;
  composes: btn-icon from "/src/index.scss";
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
  text-decoration: underline;
}

.uploadButton {
  border: 1px dashed #909498;
  border-radius: 10px;
  color: #909498;
  composes: btn-icon from "/src/index.scss";
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  gap: 0;
  height: 64px;
  justify-content: center;
  margin-top: 8px;
  padding: 4px 10px;
  width: 100%;
}

.fileInput {
  opacity: 0;
  visibility: hidden;
  width: 0;
}

.homework {
  align-items: center;
  display: flex;
  gap: 6px;
  max-width: 100%;

  :global {
    .CompletedHomeworkPill {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.box {
  background: #ffffff;
  border-radius: 40px;
  box-shadow: 0 1px 9px rgba(41, 45, 52, 0.07);
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 10px;
  max-width: 100%;
  padding: 40px 35px;
  width: 700px;
}

.lessonDetails {
  display: grid;
  font-size: 15px;
  gap: 40px;
  grid-template-columns: 1fr;
}

.lessonDetails_side {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.lessonDetails_info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.lessonDetails_info__lg {
  font-size: 16px;

  .title {
    font-size: 18px;
    line-height: 27px;
  }
}

.lessonDetails_group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.actions {
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: center;

  button {
    width: auto;
  }
}

.completedHomeworksSection {
  border-top: 1px solid rgba(242, 242, 242, 0.56);
  padding: 12px 0 18px;
}

.uploadCompletedHomework {
  max-width: 350px;
}

.lessonIsCancelledInfo {
  color: #b24b43;
  font-size: 26px;
  margin-top: 6px;
}

@media (min-width: 768px) {
  .box {
    padding: 40px 55px;
  }

  .lessonDetails {
    gap: 80px;
    grid-template-columns: 1fr 1fr;
  }

  .lessonDetails_side {
    &:last-of-type {
      order: -1;
    }
  }
}
