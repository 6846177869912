.root {
  background: #ffffff;
  border: 1px solid #dcdfe4;
  border-radius: 5px;
  grid-column: 2 / 2;
  grid-row: 1 / span 2;
  width: 440px;
}

.main {
  padding: 12px 20px 16px;
}

.topBar {
  align-items: center;
  background: #f2f2f2;
  display: flex;
  gap: 10px;
  height: 34px;
  justify-content: flex-end;
  padding: 0 15px;
}

.closeBtn {
  background: #ffffff;
  border: 1px solid #dcdfe4;
  border-radius: 5px;
  color: #7c828d;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  line-height: 21px;
  width: 34px;
}

.actions:not(:empty) {
  align-items: center;
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-bottom: 12px;
}

.info {
  font-size: 14px;
  line-height: 21px;
  margin-top: 10px;
}
