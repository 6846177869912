.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 4px;
  text-align: center;
}

.subtitle {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.submitBtn {
  justify-content: center;
  width: 140px;
}

.existingAccountText {
  font-size: 13px;
  line-height: 20px;

  :global {
    .link {
      text-decoration: underline;
    }
  }
}
