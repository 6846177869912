.root {
  background-color: #c1d5d1;
  border: 1px solid #ffffff;
  color: black;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  gap: 3px;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding: 2px 4px;
  position: absolute;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 1;

  &:hover {
    opacity: 0.8;
  }
}

.participants {
  display: inline-block;
  font-weight: 500;
  word-break: break-word;
}

.topic {
  overflow: hidden;
  word-break: break-all;
}

.hours {
  color: #91b5ac;
  display: none;
  font-size: 12px;
  font-weight: 600;
  margin-left: auto;
  position: absolute;
  right: 3px;
  top: 3px;
}

@container (min-width: 180px) {
  .root {
    font-size: 12px;
  }
}

@container (min-width: 200px) {
  .root {
    background-color: #ffffff;
    border: 1px solid #c1d5d1;
    border-left: 9px solid #c1d5d1;
    font-size: 13px;
    padding: 4px 6px;
    width: 90%;
    justify-content: space-between;
  }

  .participants {
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 66px);
    line-height: 18px;
  }

  .hours {
    display: block;
  }

  .topic {
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
    white-space: nowrap;
  }
}
