.user {
  align-items: center;
  color: #292d34;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 6px;
}

.accountIcon {
  width: 18px;
}

.popover {
  margin: 0;
  top: 5px !important;
  width: 260px;
}

.email {
  overflow: hidden;
  text-overflow: ellipsis;
}

.emailSection {
  border-bottom: 1px solid #dfe6e9;
  font-size: 14px;
  font-weight: 500;
  padding: 16px 0;
  text-align: center;
}

.footer {
  padding: 16px 0;
}

.footerInfo {
  color: #636e72;
  font-size: 11px;
  font-weight: 400;
  margin-top: 8px;
  text-align: center;

  * {
    color: #636e72;
  }

  a {
    text-decoration: underline;
  }
}

.logOutBtn {
  justify-content: center;
  margin-bottom: 12px;
  width: 100%;
}

@media (min-width: 1200px) {
  .accountIcon {
    width: 16px;
  }
}
