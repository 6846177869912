.root {
  border-radius: 12px;
  max-width: calc(100% - 2.4rem);
  min-height: 150px;
  overflow-y: auto;
  padding: 0;
}

.content {
  padding: 16px 24px;
}

.topBar {
  align-items: center;
  background: #eff0f3;
  display: flex;
  gap: 10px;
  height: 48px;
  justify-content: space-between;
  padding: 0 15px;
}

.headerBox {
  align-items: center;
  background: #ffffff;
  border: 1px solid #dcdfe4;
  border-radius: 5px;
  color: #7c828d;
  display: flex;
}

.title {
  composes: headerBox;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0.4px;
  line-height: 18px;
  padding: 0 24px;
  text-transform: uppercase;
}

.actions {
  align-items: center;
  display: flex;
  gap: 6px;
}

.action {
  composes: headerBox;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  justify-content: center;
  line-height: 21px;
  width: 34px;
}

.footer {
  align-items: center;
  border-top: 1px solid #eff0f3;
  display: flex;
  height: 60px;
  justify-content: flex-end;
  padding: 0 24px;
}

@media (min-width: 768px) {
  .root {
    min-width: 408px;
  }
}
