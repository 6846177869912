.root {
  align-items: center;
  background-color: #91b5ac;
  border-radius: 3px;
  color: #ffffff;
  cursor: help;
  display: inline-flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  line-height: 18px;
  padding: 1px 2px;

  svg {
    height: 11px;
  }
}

.small {
  height: 15.5px;
  padding: 0;
  width: 16px;
}

.closed {
  background-color: #91b5ac;
}

.paid {
  background-color: #ffffff;
  border: 1px solid #91b5ac;
  color: #91b5ac;
}

.awaits {
  background-color: #909498;
}

.afterDeadline {
  background-color: #b24b43;
}
