$hour_part_width: 37px;

.root {
  background: #ffffff;
  border: 1px solid #e9ebf0;
  max-width: 100%;
  width: 100%;
}

.header {
  align-items: center;
  border-bottom: 1px solid #e9ebf0;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 16px;
}

.header__title {
  font-size: 22px;
  line-height: 33px;
}

.header__date {
  color: #909498;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
}

.subheader {
  align-items: center;
  display: flex;
  gap: 14px;
  justify-content: center;
  padding: 12px 16px;

  button {
    font-size: 14px;
    font-weight: 500;
  }
}

.days {
}

.day {
  border-top: 1px solid #e9ebf0;
  display: flex;
}

.day__hour {
  border-right: 1px solid #e9ebf0;
  flex-shrink: 0;
  height: 60px;
  position: relative;
  text-align: center;
  width: $hour_part_width;
}

.day__content {
  flex: 1;
  height: 60px;
  max-width: calc(100% - $hour_part_width);
  position: relative;
}

.hour {
  background: white;
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  padding: 0 2px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.actions {
  align-items: center;
  background: #ffffff;
  bottom: 0;
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;

  :global {
    .Button {
      &:first-child {
        margin-left: 2px;
      }

      &:last-child {
        margin-right: 2px;
      }
    }
  }

  &:hover {
    opacity: 1;
  }
}

.action {
  border-radius: 2px;
  border-width: 2px;
  font-size: 11px;
  font-weight: 800;
  height: 42px;
  justify-content: center;
  line-height: 1;
  text-decoration-line: underline;
  width: 100%;
}
