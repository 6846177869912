.root {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  gap: 8px;
  line-height: 21px;
}

input[type="radio"] {
  appearance: none;
  background-color: #ffffff;
  border: 1px solid #292d34;
  border-radius: 50%;
  color: #197bbd;
  cursor: pointer;
  display: grid;
  flex-shrink: 0;
  font: inherit;
  height: 14px;
  margin: 0;
  place-content: center;
  width: 14px;
}

input[type="radio"]:checked {
  border: 1px solid #197bbd;
  color: #197bbd;
}

input[type="radio"]::before {
  border-radius: 50%;
  box-shadow: inset 1em 1em #197bbd;
  content: "";
  height: 6px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  width: 6px;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
