.root {
  align-items: center;
  background: rgba(25, 123, 189, 0.1);
  border: 1px solid rgba(25, 123, 189, 0.6);
  border-radius: 12px;
  cursor: pointer;
  display: inline-flex;
  font-size: 13px;
  gap: 4px;
  height: 24px;
  line-height: normal;
  max-width: 100%;
  padding: 0 8px;
}

.orange {
  background: rgba(251, 179, 90, 0.1);
  border: 1px solid #e99455;
}

.typeIconContainer {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 16px;
  justify-content: center;
  width: 16px;

  img {
    height: 100%;
    width: 100%;
  }
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.homeworkIcon {
  height: 12px;
}
