.root {
  align-items: stretch;
  background: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  padding: 24px 12px 32px;
  width: 229px;
}

.top {
  align-items: center;
  color: #000000;
  display: flex;
  font-size: 26px;
  font-weight: 400;
  gap: 12px;
  justify-content: center;
  line-height: 39px;
}

.navigation {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 40px;
  margin-top: 90px;
}

.menuItem {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #292d34;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
  height: 33px;
  line-height: 21px;
  padding-left: 10px;
  transition: all 0.3s;
  width: 100%;

  &:hover {
    background: rgb(132 173 164 / 3%);
    border: 1px solid #84ada46e;
  }
}

.menuItem__active {
  background: rgba(132, 173, 164, 0.11);
  border: 1px solid #84ada4;
}

.expandIcon {
  margin-left: auto;
  transform: rotate(-90deg);
  transition: all 0.3s;
}

.expanded {
  .expandIcon {
    transform: rotate(-180deg);
  }
}

.subItems {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
}

.menuSubItem {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #292d34;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
  height: 33px;
  line-height: 21px;
  padding: 0 14px;
  transition: all 0.3s;
  width: 100%;

  &:hover {
    background: rgb(132 173 164 / 3%);
    border: 1px solid #84ada46e;
  }

  &::before {
    background-color: #292d34;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 8px;
    width: 8px;
  }

  &:global(.active) {
    background: rgba(132, 173, 164, 0.11);
    border: 1px solid #84ada4;
  }
}

.bottom {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  gap: 6px;
  line-height: 21px;
  margin-top: auto;
  padding: 0;
}
