.page {
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: 20px auto;
  padding: 10px;
  row-gap: 10px;

  :global {
    .SidePanel {
      grid-column: 2 / 2;
      margin-left: 8px;
    }

    .Table {
      table {
        table-layout: fixed;
      }
    }
  }
}

.header {
  grid-column: 1 / 2;
}

.table {
  grid-column: 1 / 2;

  table {
    table-layout: fixed;
  }
}

.LessonDate {
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 8px;
  line-height: 21px;
}

.LessonDate_hours {
  color: #7c828d;
}

.homeworkCol {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.sidePanelActions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

// TODO: Duplicated
.sidePanelSection {
  column-gap: 20px;
  display: grid;
  grid-template-columns: 129px minmax(0, 1fr);
  padding: 8px 0 12px;
  row-gap: 10px;
}

.sidePanelSection__main {
  border-bottom: 1px solid rgba(242, 242, 242, 0.56);
  composes: sidePanelSection;
  margin-bottom: 16px;
}

.materials {
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.uploadButton {
  border: 1px dashed #909498;
  border-radius: 10px;
  color: #909498;
  composes: btn-icon from "/src/index.scss";
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  gap: 0;
  height: auto;
  justify-content: center;
  margin-top: 8px;
  padding: 4px 10px;
  width: 100%;
}

.fileInput {
  opacity: 0;
  visibility: hidden;
  width: 0;
}

.homework {
  align-items: center;
  display: flex;
  gap: 6px;
  max-width: 100%;

  :global {
    .CompletedHomeworkPill {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.detailsModal_body {
  font-size: 14px;
  outline: none;
}

.detailsModal_actions {
  border-bottom: 1px solid #eff0f3;
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  margin-bottom: 12px;
  padding-bottom: 12px;
}

@media (min-width: 768px) {
  .page {
    :global {
      .Table {
        table {
          table-layout: auto;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .page {
    display: grid;
  }
}
