.Table {
  background-color: #ffffff;
  border: 1px solid #dcdfe4;
  border-radius: 5px;
  display: grid;
  grid-template-rows: min-content auto;
  height: 100%;
  min-height: 350px;
  position: relative;

  .Table_content {
    border-collapse: collapse;
    overflow-x: auto;
    width: 100%;

    table {
      table-layout: fixed;
      width: 100%;
    }

    .Table_row--head {
      height: 45px;

      .Table_th {
        border-bottom: 1px solid rgba(171, 171, 171, 0.8);
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        overflow: hidden;
        padding: 12px;
        text-align: left;
        text-overflow: ellipsis;

        .Table_th-container {
          align-items: center;
          display: flex;

          .Table_th-sort {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-left: 4px;

            img {
              height: 11px;
              width: 11px;

              &:first-of-type {
                margin-bottom: -1px;
              }

              &:last-of-type {
                margin-top: -1px;
              }
            }
          }
        }
      }

      .Table_th--sortable {
        cursor: pointer;
      }

      .Table_th--center {
        .Table_th-container {
          justify-content: center;
        }
      }
    }

    .Table_row--body {
      border-bottom: 1px solid rgba(233, 235, 240, 0.5);
      height: 45px;
      transition: background-color 0.3s;

      .Table_td {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        overflow: hidden;
        padding: 4px 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .Table_td--email {
        & > span {
          align-items: center;
          display: flex;
          gap: 4px;

          a {
            font-weight: 400;
          }
        }
      }
    }

    .Table_row--clickable {
      cursor: pointer;

      &:hover {
        background: rgba(25, 123, 189, 0.02);
      }
    }
  }

  .Table--empty {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 550px;
    padding: 70px 10px;
    text-align: center;

    .h5 {
      font-weight: 500;
      margin-top: 12px;
    }

    .caption {
      line-height: 1.3;
      margin-top: 12px;
    }
  }

  .Table_pagination {
    align-items: center;
    background: #f2f2f2;
    display: flex;
    gap: 24px;
    height: 44px;
    justify-content: end;
    margin-top: auto;
    padding: 0 50px;
  }

  .Table_spinner {
    height: 300px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    span {
      border-color: rgb(0 0 0 / 70%) rgb(0 0 0 / 70%) transparent !important;
    }

    .Spinner_backdrop {
      display: none !important;
    }
  }
}

//
//@include for-desktop-down {
//  .Table {
//    .Table_content {
//      td {
//        max-width: 200px;
//      }
//
//      table {
//        table-layout: auto;
//      }
//    }
//  }
//}
