.root {
  padding: 10px;
}

.tabs {
  color: #7c828d;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 25px;
  line-height: 24px;
  margin-bottom: 10px;
  padding: 10px 10px 10px 25px;
}

.tab {
  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding-bottom: 8px;
}

.tab__active {
  border-bottom-color: #197bbd;
  color: #292d34;
  composes: tab;
  cursor: default;
}

.box {
  align-items: stretch;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 9px rgba(41, 45, 52, 0.07);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 30px 40px;
  width: 330px;

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
